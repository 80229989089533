.announcement {
    background: #577590ff;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    font-size: 0.8rem;
    margin-top: 4.5rem;
    position: fixed;
}

.announcement-text {
    display: flex;
    align-items: center;
    margin-right: 2rem;
}

.text1 span {
    margin-right: 2rem;
}

.text2 span:not(:last-child) {
    margin-right: 2rem;
}

.text2 span:last-child {
    margin-right: 1rem;
}

@media (max-width: 767px) {
    .announcement {
        margin-top: 0.5rem; 
    }

    .announcement-text {
        margin-right: 1rem; 
    }

    .text1 span,
    .text2 span {
        margin-right: 0; 
    }
}